import React from 'react';
import {Box, Typography} from '@mui/material';

const NotFoundPage = () => {
  return (
    <Box>
      <Typography variant="h4">OOPS! PAGE NOT FOUND</Typography>
      <Typography variant="h3">
        We are sorry, but the page you requested was not found!
      </Typography>
    </Box>
  );
};

export default NotFoundPage;
