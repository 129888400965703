import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import * as urls from '../../constants/urls';
import RouteLayout from './RouteLayout';
import NotFoundPage from './404';
import NotAuthorizedPage from './401';
import HomePage from '../Home/HomePage';
import PartnersList from '../Partners/Partners';
import PartnerDetails from '../Partners/Details';
import MyAccount from '../Account/MyAccount';

const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RouteLayout />}>
      <Route path={urls.HOME_PAGE} element={<HomePage />} />
      <Route path={urls.PARTNERS_LIST} element={<PartnersList />} />
      <Route path={urls.PARTNER_DETAILS} element={<PartnerDetails />} />
      <Route path={urls.MY_ACCOUNT_PATH} element={<MyAccount />} />
      <Route path={urls.NOT_AUTHORIZED} element={<NotAuthorizedPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Route>
  )
);
export default AppRoutes;
