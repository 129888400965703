import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {axiosBaseQuery} from './functions';

export const AccountApi = createApi({
  reducerPath: 'AccountApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['total-cashback'],
  endpoints: builder => ({
    totalCashback: builder.query({
      query: () => ({
        url: '/total-cashback',
        method: 'GET',
      }),
      providesTags: ['total-cashback'],
    }),
    myCashback: builder.mutation({
      query: () => ({
        url: '/my-cashback',
        method: 'GET',
      }),
    }),
    collectCashback: builder.mutation({
      query: () => ({
        url: '/collect-cashback',
        method: 'POST',
      }),
      invalidatesTags: ['total-cashback'],
    }),
  }),
});

export const {
  useTotalCashbackQuery,
  useMyCashbackMutation,
  useCollectCashbackMutation,
} = AccountApi;
