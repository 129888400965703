import {useEffect, useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';
import {
  Box,
  IconButton,
  Tab,
  useTheme,
  Typography,
  Button,
} from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import {StyledTabs} from '../Custom/Tabs';
import TextField from '../Custom/TextField';
import List from './List';
import {ReactComponent as ClearIcon} from '../../assets/images/clear.svg';
import {ReactComponent as CoinsIcon} from '../../assets/images/coins.svg';
import * as urls from '../../constants/urls';
import {PARTNER_TYPE} from '../../constants';

/**
 * Partners List root
 */

type TabParam = {
  tab: PARTNER_TYPE;
};

const PartnersList = () => {
  const intl = useIntl();
  const theme = useTheme();
  const {tab} = useParams<TabParam>();
  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const keyPress = (e: any) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };
  // Clear icon search handler
  const clearSearchInput = () => {
    setSearchInput('');
    setSearch('');
  };

  // Reset search after tab change
  useEffect(() => clearSearchInput(), [tab]);
  return (
    <>
      <Box sx={{px: {xs: 1.6, md: 2.4}}}>
        <Box pb={1.6} sx={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h3" component="p" className="font-weight-bold">
            <FormattedMessage id="partners.title" />
          </Typography>
          <Box sx={{m: -0.6}}>
            <Button
              variant="text"
              href={urls.MY_ACCOUNT_PATH}
              startIcon={<CoinsIcon />}
            >
              <FormattedMessage id="account.mes_gains" />
            </Button>
          </Box>
        </Box>
        <TextField
          placeholder={intl.formatMessage({id: 'common.search'})}
          value={searchInput}
          onKeyDown={keyPress}
          onChange={e => {
            setSearchInput(e.target.value);
          }}
          InputProps={{
            endAdornment:
              searchInput?.length !== 0 ? (
                <IconButton
                  size="small"
                  sx={{color: theme.palette.text.secondary}}
                  onClick={clearSearchInput}
                >
                  <ClearIcon />
                </IconButton>
              ) : undefined,
          }}
          sx={{width: {xs: '100%'}}}
        />
      </Box>
      <StyledTabs value={tab}>
        <Tab
          value={PARTNER_TYPE.ALL_PARTNERS}
          label={
            <span>{intl.formatMessage({id: 'partners.tabs.cashback'})}</span>
          }
          component={Link}
          to={urls.PARTNERS_LIST_FUNCTION(PARTNER_TYPE.ALL_PARTNERS)}
          className="tab-all"
        />
        <Tab
          value={PARTNER_TYPE.ONLINE_PARTNERS}
          label={
            <span>{intl.formatMessage({id: 'partners.tabs.online'})}</span>
          }
          component={Link}
          to={urls.PARTNERS_LIST_FUNCTION(PARTNER_TYPE.ONLINE_PARTNERS)}
          className="tab-online"
        />
        <Tab
          value={PARTNER_TYPE.OFFLINE_PARTNERS}
          label={
            <span>{intl.formatMessage({id: 'partners.tabs.offline'})}</span>
          }
          component={Link}
          to={urls.PARTNERS_LIST_FUNCTION(PARTNER_TYPE.OFFLINE_PARTNERS)}
          className="tab-offline"
        />
        <Tab
          value={PARTNER_TYPE.FAVORITE_PARTNERS}
          label={
            <span>{intl.formatMessage({id: 'partners.tabs.favorites'})}</span>
          }
          component={Link}
          to={urls.PARTNERS_LIST_FUNCTION(PARTNER_TYPE.FAVORITE_PARTNERS)}
          className="tab-favorite"
        />
      </StyledTabs>
      {/* Partners list */}
      {tab !== undefined && <List type={tab} search={search} />}
    </>
  );
};

export default PartnersList;
