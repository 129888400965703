import {configureStore} from '@reduxjs/toolkit';
import {rootReducer} from './reducers';
import {signInApi} from '../services/signInApi';
import {PartnerApi} from '../services/PartnerApi';
import {OffersApi} from '../services/OffersApi';
import {AccountApi} from '../services/accountApi';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({serializableCheck: false}).concat([
      signInApi.middleware,
      PartnerApi.middleware,
      OffersApi.middleware,
      AccountApi.middleware,
    ]),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
