import {createTheme} from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

/** App theme */
const theme = createTheme({
  palette: {
    background: {
      paper: '#FFFFFF',
      default: '#FAFAFA',
    },
    divider: '#EDEDED',
    text: {
      primary: '#2C1936',
      secondary: '#968B9B',
    },
    secondary: {
      main: '#4AC468',
    },
    error: {
      main: '#E22130',
    },
    success: {
      main: '#39A935',
    },
    warning: {
      main: '#FF8000',
    },
    info: {
      main: '#6162AA',
      light: '#968B9B',
      dark: '#000000',
    },
    neutral: {
      main: '#624971',
      dark: '#282B3E',
      light: '#EEEFF5',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: 'unset',
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 1200,
      xl: 1295,
    },
  },
  spacing: 10,
  shape: {
    borderRadius: 10,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({theme}) => ({
          [theme.breakpoints.up('lg')]: {
            maxWidth: 1294,
          },
        }),
      },
    },
    // Icon button
    MuiIconButton: {
      styleOverrides: {
        root: ({ownerState, theme}) => ({
          '&:hover': {
            backgroundColor: 'transparent',
          },
          ...(ownerState.size === 'medium' && {
            margin: theme.spacing(-0.8),
          }),
          ...(ownerState.size === 'small' && {
            margin: theme.spacing(-0.5),
          }),
        }),
      },
    },
    // Chip
    MuiChip: {
      styleOverrides: {
        root: ({theme, ownerState}) => ({
          height: theme.spacing(4),
          borderRadius: theme.spacing(4),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'outlined' && {
              borderColor: theme.palette.primary.dark,
            }),
          ...(ownerState.color === 'primary' &&
            ownerState.variant === 'filled' && {
              background: `transparent linear-gradient(95deg, ${theme.palette.primary.light} 5.84%, ${theme.palette.primary.main} 100%) 0% 0% no-repeat padding-box`,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                color: theme.palette.primary.contrastText,
              },
              [theme.breakpoints.down('md')]: {
                background: `transparent linear-gradient(112deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%) 0% 0% no-repeat padding-box`,
              },
            }),
          ...(ownerState.size === 'small' && {
            height: theme.spacing(2),
          }),
        }),
        label: ({theme, ownerState}) => ({
          fontSize: 14,
          lineHeight: 1.285,
          padding: theme.spacing(1.1, 1.8),
          fontFamily: 'Poppins Medium',
          ...(ownerState.size === 'small' && {
            fontSize: 12,
            lineHeight: 1.2,
            padding: theme.spacing(0, 1.35),
          }),
        }),
      },
    },
    // Button
    MuiButton: {
      styleOverrides: {
        root: ({theme, ownerState}) => ({
          fontFamily: 'Poppins Medium',
          fontSize: 14,
          lineHeight: 1.28,
          padding: theme.spacing(0.6),
          borderRadius: theme.spacing(3),
          boxShadow: theme.shadows[0],
          '&.Mui-disabled': {
            color: theme.palette.info.light,
            opacity: 0.4,
          },
          '& .MuiButton-startIcon': {
            marginRight: theme.spacing(1),
            marginLeft: 0,
          },
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }),
          '&:hover': {
            boxShadow: theme.shadows[0],
            textDecoration: 'none !important',
          },
          ...(ownerState.variant === 'text' && {
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: 'transparent',
              textDecoration: 'none!important',
            },
          }),
        }),
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: () => ({
          mixBlendMode: 'multiply',
          backdropFilter: 'opacity(1)',
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'unset',
        },
      },
    },
  },
});

// Font sizes
theme.typography.caption = {
  fontSize: theme.typography.pxToRem(15),
};

theme.typography.h1 = {
  fontSize: theme.typography.pxToRem(28),
  lineHeight: 'normal',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(18),
  },
};

theme.typography.h2 = {
  fontSize: theme.typography.pxToRem(24),
  lineHeight: 'normal',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(16),
  },
};

theme.typography.h3 = {
  fontSize: theme.typography.pxToRem(18),
};

theme.typography.h4 = {
  fontSize: theme.typography.pxToRem(15),
  lineHeight: 1.266,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.25,
  },
};

theme.typography.h5 = {
  fontSize: theme.typography.pxToRem(16),
  lineHeight: 1.1875,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.25,
  },
};

theme.typography.h6 = {
  fontSize: theme.typography.pxToRem(14),
  lineHeight: 1.28,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.25,
  },
};

theme.typography.body1 = {
  fontSize: theme.typography.pxToRem(14),
  lineHeight: 'normal',
};

theme.typography.body2 = {
  fontSize: theme.typography.pxToRem(18),
  lineHeight: 1.44,
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(15),
    lineHeight: 1.26,
  },
};

theme.typography.subtitle1 = {
  fontSize: 12,
  lineHeight: 'normal',
};

theme.typography.subtitle2 = {
  fontSize: 10,
};

theme.shadows[1] = '0px 0px 20px 0px rgba(0, 0, 0, 0.08)';
theme.shadows[2] = '0px -10px 20px rgba(0, 0, 0, 0.05)';

export default theme;
