import {Box, Card, Grid, Stack, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';

import InfoPopover from '../Custom/InfoPopover';
import {isUrlAbsolute} from '../../utils/functions';

type CashbackItemProps = {
  name: string;
  amount: string;
  status: string;
  logo: string;
};

type DayCashbackProps = {
  date: string;
  items: CashbackItemProps[];
};

const DayCashback = ({date, items}: DayCashbackProps) => {
  const getLogoUrl = (logo: string) =>
    logo
      ? isUrlAbsolute(logo)
        ? logo
        : process.env.REACT_APP_BUCKET_IMAGE_URL + '/logoAdvertisers/' + logo
      : '';
  const onHold = (status: string) =>
    status && !['VALIDE', 'approved'].includes(status);

  return (
    <>
      <Stack rowGap={0}>
        {items.map((item, index) => (
          <Card
            key={index}
            elevation={2}
            sx={{
              px: {xs: 1.6, md: 2.4},
              py: {xs: 1.6, md: 2},
              borderRadius: {xs: '10px 10px 0 0', md: '20px 20px 0 0'},
            }}
          >
            <Grid container alignItems="center" columnGap={{xs: 1, md: 2}}>
              <Grid item xs={7}>
                <Typography
                  variant="h5"
                  className="font-weight-bold text-truncate"
                  sx={{mb: 0.4}}
                >
                  {item.name}
                </Typography>
                <Stack
                  direction="row"
                  gap={0.5}
                  divider={
                    <Typography color="text.secondary">&bull;</Typography>
                  }
                >
                  <Typography sx={{color: 'text.secondary'}}>{date}</Typography>
                  {onHold(item.status) && (
                    <Typography>
                      <Typography component="span" color="text.secondary">
                        <FormattedMessage id="account.on_hold" />
                      </Typography>
                      <InfoPopover id={`${index}`}>
                        <Typography variant="subtitle1" color="text.secondary">
                          <FormattedMessage id="account.cashback.onhold_tooltip" />
                        </Typography>
                      </InfoPopover>
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item xs="auto" sx={{py: 0.5, ml: 'auto'}}>
                <Typography
                  variant="h2"
                  color={onHold(item.status) ? '#DCDCDC' : 'secondary.main'}
                  className="font-weight-bold"
                >
                  +{item.amount}€
                </Typography>
              </Grid>
              <Grid item xs="auto" sx={{display: 'flex', height: '100%'}}>
                <Box
                  sx={{
                    display: 'flex',
                    overflow: 'hidden',
                    width: 50,
                    maxHeight: 50,
                    height: '100%',
                  }}
                  borderRadius={0.5}
                >
                  <img
                    className="img-fluid object-fit-cover"
                    src={getLogoUrl(item.logo)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Stack>
    </>
  );
};

export default DayCashback;
