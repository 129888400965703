import {FormattedMessage, useIntl} from 'react-intl';
import {
  Grid,
  Box,
  Typography,
  Stack,
  Chip,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import BackwardButton from '../Custom/BackwardButton';
import ArrowLink from '../Custom/ArrowLink';
import {Key, useState} from 'react';
import DetailsPopup from '../Offers/DetailsPopup';
import {useDetailQuery} from '../../services/PartnerApi';
import {useListByMerchantQuery} from '../../services/OffersApi';
import {S3Url} from '../../constants/constants';
import MapComponent from './MapComponent';
import StateIndicator from '../Custom/StateIndicator';
import Accordion from '../Custom/Accordion';
import OfferCard from '../Offers/OfferCard';

/**
 * Partner details
 */

type UrlParam = {
  id: string;
};

type MerchantCardProps = {
  name: string;
  logoUrl: string;
  longDescriptionOn: string;
  descriptionOn: string;
  displayUrl: string;
  offersCount: number;
  online: boolean;
  lat: number;
  lng: number;
  type: string;
};

const MerchantCard = ({
  logoUrl,
  longDescriptionOn,
  descriptionOn,
  displayUrl,
  offersCount,
  online,
  lat,
  lng,
  type,
}: MerchantCardProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box sx={{mb: {xs: 1.6, md: 2}}}>
      <Box
        sx={{
          position: 'relative',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          mb: {xs: 1.6, md: 2},
          height: {xs: 90, md: 150},
        }}
      >
        <BackwardButton onClick={() => navigate(-1)} />
        <img
          style={{
            position: 'absolute',
            left: '50%',
            top: 10,
            transform: 'translateX(-50%)',
            width: isMobile ? 90 : 191,
            height: 'calc(100% - 20px)',
            objectFit: 'contain',
          }}
          src={type === 'AWIN' ? logoUrl : S3Url + 'logoAdvertisers/' + logoUrl}
        />
      </Box>
      <Accordion
        items={[
          {
            title: intl.formatMessage({id: 'partners.details.title'}),
            content: (
              <>
                <Grid
                  container
                  spacing={1.6}
                  sx={{flexDirection: {xs: 'column-reverse', md: 'row'}}}
                >
                  <Grid item md>
                    {/* Offers count / Merchant type */}
                    <Stack
                      direction="row"
                      gap={1}
                      sx={{pb: 1, alignItems: 'center'}}
                    >
                      <Chip
                        color="primary"
                        size="small"
                        label={`${offersCount} ${intl.formatMessage({
                          id: 'common.offers',
                        })}`}
                      />
                      {online && (
                        <StateIndicator
                          variant="success"
                          title={intl.formatMessage({
                            id: 'partners.details.online',
                          })}
                        />
                      )}
                    </Stack>
                    {/* Description */}
                    <Box pb={2}>
                      <Typography sx={{color: 'neutral.main'}}>
                        {longDescriptionOn ?? descriptionOn}
                      </Typography>
                    </Box>
                    {online ? (
                      <ArrowLink href={displayUrl}>
                        <FormattedMessage id="partners.details.see_website" />
                      </ArrowLink>
                    ) : (
                      <ArrowLink
                        href={`https://maps.google.com/?q=${lat},${lng}`}
                      >
                        <FormattedMessage id="partners.details.open_location" />
                      </ArrowLink>
                    )}
                  </Grid>
                  {!online && (
                    <Grid item md={4}>
                      <MapComponent lat={lat} lng={lng} />
                    </Grid>
                  )}
                </Grid>
              </>
            ),
          },
        ]}
      />
    </Box>
  );
};

const Details = () => {
  const {id} = useParams<UrlParam>();
  const [offerDetailsPopup, setOfferDetailsPopup] = useState({
    isOpen: false,
    id: null,
    descriptionOn: null,
  });
  const {data: partnerDetailData, error, isLoading} = useDetailQuery(id ?? '');
  const {data: offersData} = useListByMerchantQuery(id ?? '');

  return (
    <>
      <DetailsPopup
        open={offerDetailsPopup.isOpen}
        offerId={offerDetailsPopup.id}
        descriptionOn={offerDetailsPopup.descriptionOn}
        handleClose={() =>
          setOfferDetailsPopup({...offerDetailsPopup, isOpen: false})
        }
      />
      <Box sx={{px: {xs: 1.6, md: 2.4}}}>
        {isLoading && <div>loading</div>}
        {error ? <div> Oh no, there was an error </div> : null}
        {partnerDetailData && offersData ? (
          <>
            {/* Details */}
            <MerchantCard
              {...partnerDetailData}
              lat={parseFloat(partnerDetailData.pointOfSales[0]?.latitude)}
              lng={parseFloat(partnerDetailData.pointOfSales[0]?.longitude)}
            />
            {/* Offers list */}
            <Grid container columnSpacing={4} rowSpacing={{xs: 1.6, md: 2}}>
              {offersData['hydra:member'].map(
                (item: any, index: Key | null | undefined) => (
                  <Grid key={index} item xs={12} md={6}>
                    <OfferCard
                      {...item}
                      type={item['@type']}
                      openOfferDetailsPopup={() =>
                        setOfferDetailsPopup({
                          isOpen: true,
                          id: item.id,
                          descriptionOn: partnerDetailData.descriptionOn,
                        })
                      }
                    />
                  </Grid>
                )
              )}
            </Grid>
          </>
        ) : null}
      </Box>
    </>
  );
};

export default Details;
