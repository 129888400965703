import {Key, useEffect, useState} from 'react';
import {Button, Box, Typography, Stack, Grid} from '@mui/material';
import {FormattedMessage, useIntl} from 'react-intl';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
import isEmpty from 'lodash/isEmpty';
import CashbackCircle from './CashbackCircle';
import {ReactComponent as MoneyIcon} from '../../assets/images/money.svg';
import {ReactComponent as CashbackIcon} from '../../assets/images/cashback.svg';

import {
  useMyCashbackMutation,
  useTotalCashbackQuery,
  useCollectCashbackMutation,
} from '../../services/accountApi';
import DayCashback from './DayCashback';
import CashbackCollectedPopup from './CashbackCollectedPopup';
import * as urls from '../../constants/urls';

const MyAccount = () => {
  const intl = useIntl();
  // Cashback Collected popup state
  const [cashbackCollected, setCashbackCollected] = useState(false);

  const [totalCashback, setTotalCashback] = useState(0);
  const [totalCollectedCashback, setTotalCollectedCashback] = useState(0);
  const [circleValue, setCircleValue] = useState(0);
  const [myCashback, setMyCashback] = useState<any[]>([]);
  const [collectCashback, {isSuccess: collectIsSuccess}] =
    useCollectCashbackMutation();
  const {data: totalCashbackData, isSuccess: isTotalSuccess} =
    useTotalCashbackQuery({});
  const [myCashbackMutation, {isSuccess, data: myCashbackData}] =
    useMyCashbackMutation({});

  useEffect(() => {
    myCashbackMutation({});
  }, []);

  useEffect(() => {
    if (collectIsSuccess) {
      setCashbackCollected(true);
    }
  }, [collectIsSuccess]);

  useEffect(() => {
    if (isTotalSuccess && totalCashbackData) {
      setTotalCashback(totalCashbackData.totalCashback);
      setTotalCollectedCashback(totalCashbackData.totalCollectedCashback);
      setCircleValue(
        (totalCashbackData.totalCashback / 20) * 100 > 100
          ? 100
          : (totalCashbackData.totalCashback / 20) * 100
      );
    }
  }, [totalCashbackData, isTotalSuccess]);

  useEffect(() => {
    if (isSuccess && myCashbackData) {
      setMyCashback(myCashbackData);
    }
  }, [myCashbackData, isSuccess]);

  return (
    <>
      <CashbackCollectedPopup
        open={cashbackCollected}
        handleClose={() => setCashbackCollected(false)}
      />
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          gap={2}
          sx={{px: {xs: 1.6, md: 2.4}}}
        >
          <Typography component="p" variant="h3" className="font-weight-bold">
            <FormattedMessage id="account.mes_gains" />
          </Typography>
          <Button
            sx={{m: -0.6}}
            href={urls.PARTNERS_LIST.replace(':tab', 'online')}
            startIcon={<CashbackIcon />}
          >
            <FormattedMessage id="partners.title" />
          </Button>
        </Stack>
        <Grid
          container
          columnSpacing={{xs: 2.4, md: 3.2}}
          justifyContent="center"
          alignItems="center"
          sx={{my: {xs: 2.4, md: 6}}}
        >
          <Grid item md="auto">
            <CashbackCircle
              value={circleValue}
              title={totalCashback + '€'}
              subtitle={intl.formatMessage(
                {
                  id: 'account.payment_possible',
                },
                {value: '20€'}
              )}
            />
          </Grid>
          <Grid item md="auto">
            <Box mb={2} px={1} mt={{xs: 1.6, md: 0}} textAlign="center">
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent="center"
              >
                <MoneyIcon />
                <p className="font-weight-bold">
                  <Typography variant="h3" component="span">
                    {intl.formatMessage({id: 'account.withdrawn_cashback'})}
                  </Typography>{' '}
                  <Typography variant="h3" color="primary" component="span">
                    {totalCollectedCashback.toFixed(2)}€
                  </Typography>
                </p>
              </Stack>
            </Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={totalCashback < 20}
              sx={{minWidth: {md: 228}}}
              onClick={collectCashback}
            >
              {intl.formatMessage({id: 'account.withdraw'})}
            </Button>
          </Grid>
        </Grid>
        {/* Cashback by days */}
        {!isEmpty(myCashback) &&
          Object.keys(myCashback).map(
            (date: string, index: Key | null | undefined) => {
              return (
                <DayCashback
                  key={index}
                  date={dayjs(date, 'DD/MM/YYYY').locale('fr').format('D MMMM')}
                  items={myCashback[date]}
                />
              );
            }
          )}
      </Box>
    </>
  );
};

export default MyAccount;
