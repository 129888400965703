import {
  Modal,
  Box,
  Typography,
  Paper,
  Button,
  Stack,
  IconButton,
} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as CloseIcon} from '../../assets/images/close.svg';

/**
 * Popup that says cashback collected
 */

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export type CashbackCollectedPopupProps = {
  open: boolean;
  handleClose: () => void;
};

const CashbackCollectedPopup = ({
  open,
  handleClose,
}: CashbackCollectedPopupProps) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="Cashback collected"
    aria-describedby="Popup that says cashback collected"
  >
    <Paper
      elevation={0}
      sx={{
        ...style,
        width: {xs: 'calc(100% - 32px)', md: 433},
      }}
    >
      <IconButton
        sx={{position: 'absolute', top: 30, right: {xs: 16, md: 30}}}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{p: 3, textAlign: 'center'}}>
        <Box
          sx={{display: 'inline-flex', mx: 'auto', maxWidth: {xs: 78, md: 110}}}
        >
          <img className="img-fluid" src="/images/offers.svg" alt="" />
        </Box>
        <Box sx={{mt: 3, mb: {xs: 1, md: 3}}}>
          <Stack gap={{xs: 1, md: 2}}>
            <Typography
              variant="h1"
              component="p"
              className="font-weight-bold text-truncate"
            >
              <FormattedMessage id="account.cashback_collected.title" />
            </Typography>
            <Typography
              variant="h3"
              component="p"
              sx={{fontSize: {xs: 14, md: 18}}}
            >
              <FormattedMessage id="account.cashback_collected.text" />
            </Typography>
          </Stack>
        </Box>
        <Button
          variant="text"
          sx={{p: 1, m: -1, fontSize: {md: 18}}}
          className="font-weight-normal"
          href="/see-more"
        >
          <FormattedMessage id="account.cashback_collected.see_more_offers" />
        </Button>
      </Box>
    </Paper>
  </Modal>
);

export default CashbackCollectedPopup;
