import {AppBar, AppBarProps, Box} from '@mui/material';
import {Link} from 'react-router-dom';
import * as urls from '../../constants/urls';
import {ReactComponent as Logo} from '../../assets/images/sos-logo.svg';

const MainAppBar = ({children}: AppBarProps) => (
  <AppBar
    position="static"
    color="transparent"
    elevation={0}
    sx={{display: {xs: 'none', md: 'block'}}}
  >
    <Box sx={{display: 'flex', px: {sm: 1.7, md: 4.6}, pt: 2.6, pb: 3}}>
      <Link to={urls.HOME_PAGE}>
        <Logo />
      </Link>
      <Box sx={{ml: 'auto'}}>{children}</Box>
    </Box>
  </AppBar>
);

export default MainAppBar;
