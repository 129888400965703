import {IconButton, ButtonProps, styled} from '@mui/material';
import {ReactComponent as BackwardIcon} from '../../assets/images/backward.svg';

const BackwardButton = styled((props: ButtonProps) => (
  <IconButton {...props}>
    <BackwardIcon />
  </IconButton>
))(({theme}) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1.8, 2),
  borderRadius: theme.spacing(0.8),
  margin: 0,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.8,
  },
}));

export default BackwardButton;
