import {useMemo} from 'react';
import {GoogleMap, useLoadScript, Marker} from '@react-google-maps/api';

type MapProps = {
  lat: number;
  lng: number;
};

const MapComponent = ({lat, lng}: MapProps) => {
  const {isLoaded} = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  const center = useMemo(() => ({lat, lng}), []);

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <GoogleMap
      zoom={10}
      center={center}
      mapContainerStyle={{
        width: '100%',
        height: '100%',
        minHeight: 143,
        borderRadius: 8,
      }}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

export default MapComponent;
