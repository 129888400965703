import {combineReducers} from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import {signInApi} from '../services/signInApi';
import {PartnerApi} from '../services/PartnerApi';
import {OffersApi} from '../services/OffersApi';
import {AccountApi} from '../services/accountApi';

export const rootReducer = combineReducers({
  auth: authSlice,
  [signInApi.reducerPath]: signInApi.reducer,
  [PartnerApi.reducerPath]: PartnerApi.reducer,
  [OffersApi.reducerPath]: OffersApi.reducer,
  [AccountApi.reducerPath]: AccountApi.reducer,
});
