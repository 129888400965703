import {FormattedMessage, useIntl} from 'react-intl';
import {Button, Stack, Typography} from '@mui/material';
import {useGetOfferQuery} from '../../services/OffersApi';
import {OFFER_TYPE} from '../../constants';
import OfferCard from './OfferCard';
import CustomDialog from '../Custom/Dialog';
import CustomAccordion from '../Custom/Accordion';

export type DetailsPopupProps = {
  open: boolean;
  handleClose: () => void;
  offerId?: number | null;
  descriptionOn: string | null;
};

const DetailsPopup = ({
  open,
  handleClose,
  offerId,
  descriptionOn,
}: DetailsPopupProps) => {
  const intl = useIntl();
  const {
    data: offer,
    isError,
    isLoading,
  } = useGetOfferQuery(offerId ?? 0, {skip: !offerId});
  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      title={intl.formatMessage({id: 'offerDeatils.offer_details'})}
      bottomAction={
        offer ? (
          <Button
            href={
              offer['@type'] === OFFER_TYPE.WEB_OFFER
                ? offer.clickThroughUrl
                : `https://maps.google.com/?q=${offer?.pointsOfSale[0]?.latitude},${offer?.pointsOfSale[0]?.longitude}`
            }
            variant="contained"
            color="primary"
            fullWidth
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="offerDeatils.get_offer" />
          </Button>
        ) : null
      }
    >
      {isLoading && <div>loading</div>}
      {isError && <div>error</div>}
      {offer && (
        <Stack gap={2}>
          <OfferCard {...offer} type={offer['@type']} mobileSizes />
          <CustomAccordion
            items={[
              {
                title: intl.formatMessage({id: 'offerDeatils.description'}),
                content: (
                  <>
                    <Typography sx={{color: 'neutral.main'}}>
                      {descriptionOn}
                    </Typography>
                  </>
                ),
              },
              {
                title: intl.formatMessage({id: 'offerDeatils.conditions'}),
                content: (
                  <>
                    <Typography sx={{color: 'neutral.main'}}>
                      {offer.label !== null
                        ? offer.label
                        : offer.groupName !== null
                        ? offer.groupName
                        : null}
                    </Typography>
                  </>
                ),
              },
              {
                title: intl.formatMessage({id: 'offerDeatils.where_to_get'}),
                content: (
                  <>
                    {offer['@type'] === 'WebOffer' && (
                      <Typography sx={{color: 'neutral.main'}}>
                        <FormattedMessage id="offerDeatils.where_to_get_online" />{' '}
                      </Typography>
                    )}
                    {offer['@type'] === 'PhysicalOffer' && (
                      <>
                        <Typography sx={{color: 'neutral.main'}}>
                          {offer?.pointsOfSale[0]?.name}
                        </Typography>
                        <Typography sx={{color: 'neutral.main'}}>
                          {offer?.pointsOfSale[0]?.address} ,{' '}
                          {offer?.pointsOfSale[0]?.city}{' '}
                        </Typography>
                      </>
                    )}
                  </>
                ),
              },
            ]}
          />
        </Stack>
      )}
    </CustomDialog>
  );
};

export default DetailsPopup;
