import {matchPath, useLocation} from 'react-router-dom';
import {LoginParams, SOSLoginParams} from '../services/signInApi';
import * as COLORS from '../constants/colors';

/**
 * Flatted messages for react-intl
 * input {"a":{"b":"c"}}
 * output {"a.b":"c"}
 * @param {*} nestedMessages
 * @param {*} prefix
 */
export const flattenMessages = (nestedMessages: any, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, {[prefixedKey]: value});
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const useRouteMatch = (patterns: readonly string[]) => {
  const {pathname} = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
};

export const getLoginBody = (searchParams: any): LoginParams => {
  return {
    body: {
      pcs_jwt: searchParams.get('pcs_jwt'),
    },
  };
};

export const getSOSLoginBody = (searchParams: any): SOSLoginParams => {
  return {
    body: {
      sos_jwt: searchParams.get('sos_jwt'),
    },
  };
};

/**
 * Get primary color by env var
 * SOSHOP by default
 */
export const PRIMARY_COLORS = (origin: string | undefined) => {
  const colors = COLORS[origin as keyof typeof COLORS] ?? COLORS.PCS;
  return {
    main: colors[0],
    light: colors[1] ?? colors[0],
    dark: colors[2] ?? colors[0],
  };
};

export function isUrlAbsolute(url: string) {
  const regex = new RegExp('^(?:[a-z+]+:)?//', 'i');

  return regex.test(url);
}
