import {IntlProvider} from 'react-intl';
import {CssBaseline, ThemeProvider} from '@mui/material';
import AppRoutes from './components/Routes/Routes';
import theme from './constants/theme';
import frMessages from './translations/messages.fr.json';
import {flattenMessages, PRIMARY_COLORS} from './utils/functions';
import {RouterProvider} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {selectOrigin} from './store/slices/authSlice';
import {useEffect, useMemo} from 'react';
import {login} from './store/slices/authSlice';

function App() {
  // Locale
  const appLocale = 'fr';

  // Translations
  const messages = {
    fr: frMessages,
  };
  const dispatch = useDispatch();
  const origin = useSelector(selectOrigin);
  const dynamicTheme = useMemo(
    () => ({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          ...PRIMARY_COLORS(origin),
          contrastText: '#FFFFFF',
        },
      },
    }),
    [origin]
  );

  // check storage for login credentials
  useEffect(() => {
    const token = localStorage.getItem('token');
    const origin = localStorage.getItem('origin');
    if (token && origin) {
      dispatch(
        login({
          token: token,
          origin: origin,
        })
      );
    }
  }, []);

  return (
    <IntlProvider
      locale={appLocale}
      defaultLocale="fr"
      messages={flattenMessages(messages[appLocale])}
    >
      <ThemeProvider theme={dynamicTheme}>
        <CssBaseline />
        <RouterProvider router={AppRoutes} />
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
