export enum PARTNER_TYPE {
  ALL_PARTNERS = 'all',
  ONLINE_PARTNERS = 'online',
  OFFLINE_PARTNERS = 'offline',
  FAVORITE_PARTNERS = 'favorites',
}

export enum OFFER_TYPE {
  PHYSICAL_OFFER = 'PhysicalOffer',
  WEB_OFFER = 'WebOffer',
}
