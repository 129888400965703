import {useState, ReactNode} from 'react';
import {styled} from '@mui/material/styles';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {Stack} from '@mui/material';
import ArrowExpandIcon from './ArrowExpandIcon';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: 'none',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowExpandIcon />} {...props} />
))(({theme}) => ({
  backgroundColor: theme.palette.neutral.light,
  padding: theme.spacing(1.6),
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(0),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(0, 1.6, 1.6, 1.6),
  backgroundColor: theme.palette.neutral.light,
}));

type CustomAccordionItem = {
  title: string;
  content: string | ReactNode;
};

export type CustomAccordionProps = {
  items: CustomAccordionItem[];
};

const CustomAccordion = ({items}: CustomAccordionProps) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <Stack gap={1}>
      {items && items.length > 0
        ? items.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}d-content`}
                id={`panel${index + 1}d-header`}
              >
                <Typography sx={{color: 'neutral.dark'}}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{item.content}</AccordionDetails>
            </Accordion>
          ))
        : null}
    </Stack>
  );
};

export default CustomAccordion;
