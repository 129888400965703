import {styled, Box, Typography} from '@mui/material';

const StyledBox = styled(Box)(({theme}) => ({
  position: 'absolute',
  bottom: -7,
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 23,
  minWidth: 43,
  background: `linear-gradient(95deg, ${theme.palette.primary.light} 5.84%, ${theme.palette.primary.main} 100%)`,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(0, 0.5),
  borderRadius: theme.spacing(0.5, 0.5, 0, 0),
  textAlign: 'center',
  '& .wrapper': {
    position: 'relative',
    width: '100%',
  },
  '& .triangle-right': {
    position: 'absolute',
    top: 'calc(100% - 7px)',
    left: 'calc(100% + 5px)',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '7px 7px 0 0',
    borderColor: `${theme.palette.primary.dark} transparent transparent transparent`,
    transform: 'rotate(0deg)',
  },
  '& .triangle-left': {
    position: 'absolute',
    top: 'calc(100% - 7px)',
    left: '-12px',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 7px 7px 0',
    borderColor: `transparent ${theme.palette.primary.dark} transparent transparent`,
    transform: 'rotate(0deg)',
  },
}));

const TopOffer = ({label}: any) => {
  return (
    <StyledBox>
      <div className="wrapper">
        <Typography
          variant="subtitle1"
          component="span"
          className="font-weight-medium"
        >
          {label}
        </Typography>
        <span className="triangle-right"></span>
        <span className="triangle-left"></span>
      </div>
    </StyledBox>
  );
};

export default TopOffer;
