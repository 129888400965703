import {
  styled,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {ReactComponent as CloseIcon} from '../../assets/images/close.svg';

const StyledDialog = styled(Dialog)(({theme}) => ({
  [theme.breakpoints.down('md')]: {
    top: theme.spacing(9.8),
  },
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down('md')]: {
      borderRadius: theme.spacing(1.6, 1.6, 0, 0),
    },
    [theme.breakpoints.up('md')]: {
      width: 440,
      maxHeight: 538,
      margin: 20,
    },
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(2.4, 2.4, 1.2),
    ...theme.typography.h3,
    fontFamily: 'Poppins Bold',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.6, 1.6, 1.2),
    },
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1.2, 2.4, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.2, 1.6, 0),
    },
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2.4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2.4, 1.6, 1.6),
    },
  },
}));

const CustomDialog = ({
  title,
  children,
  bottomAction,
  open,
  handleClose,
}: any) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledDialog
      open={open}
      fullScreen={fullScreen}
      onBackdropClick={handleClose}
      PaperProps={{
        elevation: 0,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        sx={{
          position: 'absolute',
          right: {xs: 19, md: 24},
          top: {xs: 19, md: 27},
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{bottomAction}</DialogActions>
    </StyledDialog>
  );
};

export default CustomDialog;
