import {styled} from '@mui/material/styles';
import Tabs, {TabsProps} from '@mui/material/Tabs';

/**
 * Styled tabs
 */

export const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    scrollButtons="auto"
    variant="scrollable"
    TabIndicatorProps={{
      children: <div />,
    }}
  />
))(({theme}) => ({
  margin: theme.spacing(0.8, 0, 0),
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -2,
    width: '100%',
    height: 4,
    backgroundColor: theme.palette.neutral.light,
  },
  '& .MuiTabs-flexContainer': {
    margin: theme.spacing(0, -1.4),
    padding: theme.spacing(0, 2.4),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1.6),
    },
  },
  '& .MuiTab-root': {
    position: 'relative',
    textTransform: 'none',
    overflow: 'visible',
    fontFamily: 'Poppins Medium !important',
    fontSize: theme.typography.pxToRem(14),
    margin: theme.spacing(0.8, 0.5),
    color: '#9A9A9A',
    padding: theme.spacing(0, 0.2),
    minHeight: 'unset',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.primary.main,
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 75,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
