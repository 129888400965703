import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './functions';

export type LoginParams = {
  body: {
    pcs_jwt: string | null;
  };
};

export type SOSLoginParams = {
  body: {
    sos_jwt: string | null;
  };
};

export const signInApi = createApi({
  reducerPath: 'signInApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    login: builder.mutation<string, LoginParams | SOSLoginParams>({
      query: ({body}: LoginParams | SOSLoginParams) => ({
        // eslint-disable-next-line no-prototype-builtins
        url: body.hasOwnProperty('pcs_jwt')
          ? '/pcs_anonymous_login'
          : '/sos_anonymous_login',
        method: 'POST',
        data: body,
        sendAuth: false,
      }),
    }),
  }),
});

export const {useLoginMutation} = signInApi;
