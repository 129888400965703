import {FormattedMessage} from 'react-intl';
import {Card, Grid, Stack, Typography, styled, Box} from '@mui/material';
import {OFFER_TYPE} from '../../constants';

const AmountBox = styled(Box)(({theme}) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(95deg, ${theme.palette.primary.light} 5.84%, ${theme.palette.primary.main} 100%)`,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(0.1, 2),
  minHeight: 41,
  borderRadius: `${theme.spacing(0.8, 0, 0, 0.8)}`,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: -7,
    right: 0,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '7px 0 0 7px',
    borderColor: `transparent transparent transparent ${theme.palette.primary.dark}`,
    transform: 'rotate(0deg)',
    zIndex: 1,
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: -7,
    right: 0,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '7px 7px 0 0',
    borderColor: `${theme.palette.primary.dark} transparent transparent transparent`,
    transform: 'rotate(0deg)',
    zIndex: 1,
  },
}));

const OfferCard = ({
  groupName,
  label,
  dateEnd,
  variableCommission,
  customerFixedCommission,
  amount,
  percentage,
  type,
  openOfferDetailsPopup,
  mobileSizes = false,
}: any) => {
  const hasAction = typeof openOfferDetailsPopup === 'function';
  return (
    <Card
      sx={{
        p: {xs: 1.6, md: 2, overflow: 'unset'},
        mr: 0.7,
        height: '100%',
        cursor: hasAction ? 'pointer' : 'default',
      }}
      onClick={() => (hasAction ? openOfferDetailsPopup() : null)}
    >
      <Grid
        container
        spacing={2}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          overflow: 'unset',
        }}
      >
        <Grid item xs>
          <Stack gap={0.5}>
            <Typography>
              {label !== null ? label : groupName !== null ? groupName : null}
            </Typography>
            {dateEnd ? (
              <Typography sx={{color: 'info.light'}}>
                <FormattedMessage id="partners.details.expiration_date" />
                {new Date(dateEnd).toISOString().split('T')[0]}
              </Typography>
            ) : null}
          </Stack>
        </Grid>
        <Grid item xs={4} md={mobileSizes ? 4 : 3}>
          <AmountBox sx={{mr: {xs: -2.3, md: -2.7}}}>
            <Typography variant="subtitle1" component="p">
              <span>
                <FormattedMessage id="common.from" />
              </span>{' '}
              {type === OFFER_TYPE.WEB_OFFER ? (
                <span className="text-nowrap">
                  {amount && 0 && <>{amount} €</>}
                  {percentage && <>{percentage} %</>}
                </span>
              ) : (
                <span className="text-nowrap">
                  {customerFixedCommission !== 0 && (
                    <>{customerFixedCommission} €</>
                  )}
                  {variableCommission && <>{variableCommission} %</>}
                </span>
              )}{' '}
              <span>
                <FormattedMessage id="partners.details.cashback_amount_part" />
              </span>
            </Typography>
          </AmountBox>
        </Grid>
      </Grid>
    </Card>
  );
};

export default OfferCard;
