import {Box, styled} from '@mui/material';

const CouponImage = styled(Box)(({theme}) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  '&.picture': {
    boxShadow: `inset 0 0 0 1px ${theme.palette.divider}`,
    height: 56,
    padding: theme.spacing(0.8, 0.5),
  },
  '& .img-fluid': {
    padding: theme.spacing(0, 0.1),
    maxHeight: '100%',
    objectFit: 'contain',
  },
}));

export default CouponImage;
