import React, {useState} from 'react';
import {Box, Popover, useTheme} from '@mui/material';
import {ReactComponent as InfoIcon} from '../../assets/images/info.svg';

/**
 * Popover with hint text
 */

export type InfoPopoverProps = {
  id: string;
  children: any;
};

const InfoPopover = ({id, children}: InfoPopoverProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        component="span"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{display: 'inline-flex', ml: 0.3}}
        role="button"
      >
        <InfoIcon />
      </Box>
      <Popover
        id={`info-popover-${id}`}
        sx={{
          pointerEvents: 'none',
          mt: 0.8,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        hideBackdrop
        PaperProps={{
          sx: {
            boxShadow: theme.shadows[1],
            p: 1,
            borderRadius: 0.5,
            width: 250,
          },
        }}
      >
        {children}
      </Popover>
    </>
  );
};

export default InfoPopover;
