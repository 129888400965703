import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {Button, Container} from '@mui/material';
import {RouletteSpinnerOverlay} from 'react-spinner-overlay';
import jwt_decode from 'jwt-decode';

import {useLoginMutation} from '../../services/signInApi';
import {getLoginBody, getSOSLoginBody} from '../../utils/functions';
import {login} from '../../store/slices/authSlice';
import MainAppBar from '../Header/MainAppBar';
import * as urls from '../../constants/urls';
import {PARTNER_TYPE} from '../../constants';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [
    loginMutation,
    {isSuccess, data, isUninitialized, isLoading, isError},
  ] = useLoginMutation();

  // Login on Mount
  useEffect(() => {
    if (searchParams.get('pcs_jwt')) {
      const params = getLoginBody(searchParams);
      loginMutation(params);
    } else if (searchParams.get('sos_jwt')) {
      const params = getSOSLoginBody(searchParams);
      loginMutation(params);
    }
  }, []);

  // Save token in state and redirect if Login OK
  useEffect(() => {
    if (isSuccess && data) {
      localStorage.setItem('token', data);
      const decoded: any = jwt_decode(data);
      localStorage.setItem('origin', decoded.origin);
      dispatch(
        login({
          token: data,
          origin: decoded.origin,
        })
      );
      navigate(urls.PARTNERS_LIST_FUNCTION(PARTNER_TYPE.ONLINE_PARTNERS));
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      navigate(urls.NOT_AUTHORIZED);
    }
  }, [isError]);

  return (
    <>
      <MainAppBar>
        <Button
          variant="contained"
          href={urls.MY_ACCOUNT_PATH}
          sx={{minWidth: {md: 152}}}
        >
          <FormattedMessage id="account.mes_gains" />
        </Button>
      </MainAppBar>
      <Container>
        <RouletteSpinnerOverlay
          loading={isUninitialized || isLoading}
          color="#b92667"
        />
      </Container>
    </>
  );
};

export default Home;
