import {useIntl} from 'react-intl';
import {Box, IconButton, Stack, styled, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import * as urls from '../../constants/urls';
import {ReactComponent as FavoriteIcon} from '../../assets/images/favorite.svg';
import {ReactComponent as FavoriteOffIcon} from '../../assets/images/favorite-off.svg';
import CouponImage from '../Custom/CouponImage';
import Coupon from '../Custom/Coupon';
import {useEffect, useState} from 'react';
import {isUrlAbsolute} from '../../utils/functions';
import {PARTNER_TYPE} from '../../constants';
import {
  useFavoriteMutation,
  useUnfavoriteMutation,
} from '../../services/PartnerApi';
import StateIndicator from '../Custom/StateIndicator';
import TopOffer from '../Custom/TopOffer';

/**
 * Partners list item
 */

type MaxOfferType = {
  amount: number;
  isFix: boolean;
};

export type ItemProps = {
  favorite?: boolean;
  online?: boolean;
  logoUrl?: string;
  offersCount?: number;
  maxOffer: MaxOfferType;
  getPartners: Function;
  name: string;
  id: string;
  tab: PARTNER_TYPE;
};

const StyledIconButton = styled(IconButton)(({theme}) => ({
  '& > svg': {
    width: 20,
    height: 20,
    [theme.breakpoints.down('md')]: {
      width: 20,
      height: 20,
    },
  },
}));

const Item = (props: ItemProps) => {
  const intl = useIntl();
  const [logo, setLogo] = useState<string>('');
  const [max, setMax] = useState<string>('');
  const [isFavoritePage, setIsFavoritePage] = useState<boolean>(false);
  const {id, name, favorite, online, logoUrl, /*offersCount,*/ tab, maxOffer} =
    props;
  const detailsUrl = urls.PARTNER_DETAILS.replace(':id', id);
  const [doFavorite] = useFavoriteMutation();
  const [doUnfavorite] = useUnfavoriteMutation();

  useEffect(() => {
    setIsFavoritePage(PARTNER_TYPE.FAVORITE_PARTNERS === tab);
  }, [tab]);

  useEffect(() => {
    const symbol: string = maxOffer.isFix ? '€' : '%';
    setMax(`${maxOffer.amount}${symbol}`);
  }, [maxOffer]);

  useEffect(() => {
    const url = logoUrl
      ? isUrlAbsolute(logoUrl)
        ? logoUrl
        : process.env.REACT_APP_BUCKET_IMAGE_URL + '/logoAdvertisers/' + logoUrl
      : '';

    setLogo(url);
  }, [logoUrl]);

  return (
    <Link to={detailsUrl} className="hidden-link">
      <Coupon
        key={id}
        couponImage={
          <CouponImage className="picture" borderRadius={1}>
            <img src={logo} className="img-fluid" />
          </CouponImage>
        }
      >
        <Stack justifyContent="space-between" sx={{gap: 2}} height="100%">
          <Box>
            <Stack
              sx={{pb: 0.4}}
              gap={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="h5"
                className="font-weight-bold text-truncate"
              >
                {name}
              </Typography>
              <Stack direction="row" sx={{gap: 0.5, alignItems: 'center'}}>
                <>
                  {favorite || isFavoritePage ? (
                    <StyledIconButton
                      onClick={e => {
                        e.preventDefault();
                        doUnfavorite({id});
                      }}
                    >
                      <FavoriteIcon />
                    </StyledIconButton>
                  ) : (
                    <StyledIconButton
                      onClick={e => {
                        e.preventDefault();
                        doFavorite({id});
                      }}
                    >
                      <FavoriteOffIcon />
                    </StyledIconButton>
                  )}
                </>
              </Stack>
            </Stack>
            {online && (
              <StateIndicator
                variant="success"
                title={intl.formatMessage({id: 'partners.details.online'})}
              />
            )}
          </Box>
        </Stack>
        <TopOffer label={max} />
      </Coupon>
    </Link>
  );
};

export default Item;
