import {Box, Stack, Typography} from '@mui/material';

export type StateIndicatorProps = {
  variant: 'success' | 'warning' | 'error';
  title: string;
};

const StateIndicator = ({variant, title}: StateIndicatorProps) => {
  const color = variant + '.main';

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box
          sx={{
            width: 7,
            height: 7,
            borderRadius: '50%',
            backgroundColor: color,
          }}
        />
        <Typography
          variant="subtitle1"
          color={color}
          component="span"
          className="font-weight-normal"
        >
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};

export default StateIndicator;
