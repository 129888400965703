import {Container} from '@mui/material';
import {Outlet} from 'react-router-dom';

const RouteLayout = () => {
  return (
    <>
      <Container
        sx={{
          height: '100%',
          pt: {xs: 1.6, md: 2.4},
          pb: {xs: 3.7},
          px: {xs: 0},
          backgroundColor: 'background.paper',
          maxWidth: {sm: 855},
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export default RouteLayout;
