import {styled} from '@mui/material/styles';
import TextField, {TextFieldProps} from '@mui/material/TextField';

/**
 * Search text field
 */
const StylesTextField = styled((props: TextFieldProps) => (
  <TextField variant="outlined" {...props} />
))(({theme}) => ({
  '& .MuiInputBase-root': {
    padding: theme.spacing(1.4, 1.2),
    backgroundColor: theme.palette.neutral.light,
    borderRadius: theme.spacing(0.8),
  },
  '& .MuiInputBase-input': {
    padding: theme.spacing(0),
    fontFamily: 'Poppins',
    '&::-webkit-input-placeholder': {
      color: '#A5A6AA',
      opacity: 1,
      fontFamily: 'Poppins',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
}));

export default StylesTextField;
