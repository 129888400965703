import {ReactNode} from 'react';
import {Box, styled, Stack} from '@mui/material';

export type CouponProps = {
  couponImage: ReactNode;
  children: any;
};

const StyledBox = styled(Box)(({theme}) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[1],
  padding: theme.spacing(1),
  height: '100%',
  minHeight: 128,
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(2.3),
  },
}));

const Coupon = (props: CouponProps) => {
  const {couponImage, children} = props;
  return (
    <StyledBox>
      <Stack spacing={1}>
        <Box>{couponImage}</Box>
        {children}
      </Stack>
    </StyledBox>
  );
};

export default Coupon;
