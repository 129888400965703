import {SvgIcon, useTheme} from '@mui/material';

const ArrowExpandIcon = () => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 13 9" style={{width: 13, height: 9}}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 8.5L5.77595e-07 1.89309L1.37055 0.499999L6.5 5.71382L11.6295 0.5L13 1.89309L6.5 8.5Z"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  );
};

export default ArrowExpandIcon;
