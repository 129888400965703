import React from 'react';
import {Box, Typography} from '@mui/material';

const NotAuthorizedPage = () => {
  return (
    <Box>
      <Typography variant="h4">OOPS! Not Authorized</Typography>
    </Box>
  );
};

export default NotAuthorizedPage;
