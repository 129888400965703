import {
  CircularProgress,
  CircularProgressProps,
  useTheme,
  Stack,
  Typography,
  Box,
} from '@mui/material';

const CashbackCircle = (
  props: CircularProgressProps & {
    value: number;
    title: string;
    subtitle: string;
  }
) => {
  const theme = useTheme();
  return (
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress
        variant="determinate"
        color="primary"
        thickness={4}
        size={217}
        sx={{
          position: 'relative',
          borderRadius: '50%',
          strokeLinecap: 'round',
          backgroundColor: '#EEEFF5',
          '&:before': {
            content: '""',
            position: 'absolute',
            top: theme.spacing(2),
            left: theme.spacing(2),
            right: theme.spacing(2),
            bottom: theme.spacing(2),
            borderRadius: '50%',
            backgroundColor: theme.palette.background.paper,
          },
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          rowGap={0.5}
          sx={{
            maxWidth: 117,
            textAlign: 'center',
          }}
        >
          <Typography variant="h2" component="p" className="font-weight-bold">
            {props.title}
          </Typography>
          <Typography variant="subtitle1" color="neutral.dark">
            {props.subtitle}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default CashbackCircle;
