import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './functions';

export const OffersApi = createApi({
  reducerPath: 'OffersApi',
  baseQuery: axiosBaseQuery(),
  endpoints: builder => ({
    listByMerchant: builder.query({
      query: (id: string) => ({
        url: '/offers?merchant.id=' + id,
        method: 'GET',
      }),
    }),
    getOffer: builder.query({
      query: (id: number) => ({
        url: '/offers/' + id,
        method: 'GET',
      }),
    }),
  }),
});

export const {useListByMerchantQuery, useGetOfferQuery} = OffersApi;
