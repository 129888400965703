import {
  Box,
  BoxProps,
  Stack,
  SvgIcon,
  SvgIconProps,
  Typography,
} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {ReactComponent as StarIcon} from '../../assets/images/star-gray.svg';

const ShadowCircle = styled((props: BoxProps) => <Box {...props} />)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 100,
  height: 100,
  backgroundImage: 'url("/images/circle-bg.png")',
  backgroundSize: 'cover',
}));

export type NoSearchFoundProps = {
  isFavorite: boolean;
  text1: string;
  text2?: string | null;
};

const QuestionIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon viewBox="0 0 60 60" style={{width: 60, height: 60}} {...props}>
      <defs>
        <linearGradient
          id="a"
          x1=".5"
          x2=".5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor={theme.palette.primary.main} />
          <stop offset="1" stopColor={theme.palette.primary.light} />
        </linearGradient>
      </defs>
      <g data-name="Group 1467">
        <path
          data-name="Path 679"
          d="m63 375-6.45-6.312L58.983 360l-8.748-2.231L48 349.017l-8.692 2.433L33 345l-6.312 6.45L18 349.017l-2.231 8.748L7.017 360l2.433 8.692L3 375l6.45 6.312L7.017 390l8.748 2.231L18 400.983l8.692-2.433L33 405l6.312-6.45L48 400.983l2.231-8.748L58.983 390l-2.433-8.692z"
          transform="translate(-3 -345)"
          fill="url(#a)"
        />
        <text
          data-name="?"
          transform="translate(24.032 39.3)"
          fill="#fff"
          fontSize="25"
          fontFamily="Montserrat-Regular, Montserrat"
        >
          <tspan x="0" y="0">
            ?
          </tspan>
        </text>
      </g>
    </SvgIcon>
  );
};

const NoSearchFound = ({isFavorite, text1, text2}: NoSearchFoundProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      pt: {xs: 7.7, md: 10.1},
    }}
  >
    <Stack
      sx={{
        gap: {xs: 2, md: 3},
        px: {xs: 2.6, md: 0},
        alignItems: 'center',
        maxWidth: {sm: 269},
      }}
    >
      <ShadowCircle>
        {isFavorite ? <StarIcon /> : <QuestionIcon />}
      </ShadowCircle>
      <Stack>
        <Typography
          variant="caption"
          className="font-weight-bolder text-uppercase text-center"
        >
          {text1}
        </Typography>
        {text2 && (
          <Typography variant="caption" className="text-center">
            {text2}
          </Typography>
        )}
      </Stack>
    </Stack>
  </Box>
);

export default NoSearchFound;
