import {useEffect, useState} from 'react';
import {Box, Grid, useTheme} from '@mui/material';
import {useIntl} from 'react-intl';
import {RouletteSpinnerOverlay} from 'react-spinner-overlay';

import Item from './Item';
import NoSearchFound from './NoSearchFound';
import {useListQuery} from '../../services/PartnerApi';
import {PARTNER_TYPE} from '../../constants';

/**
 * Partners list
 */

export type ListProps = {
  type: PARTNER_TYPE;
  search: string;
};

const List = ({type, search}: ListProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const [itemsPerPage, setItemPerPage] = useState(30);
  const isFavorite = type === PARTNER_TYPE.FAVORITE_PARTNERS;
  const {data, isUninitialized, isLoading, isFetching} = useListQuery({
    type,
    search,
    itemsPerPage,
  });

  // Load more partners on Scroll
  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      if (scrolledToBottom && !isFetching) {
        setItemPerPage(itemsPerPage + 30);
      }
    };
    document.addEventListener('scroll', onScroll);

    return function () {
      document.removeEventListener('scroll', onScroll);
    };
  }, [itemsPerPage, isFetching]);

  // Reset item per page on page change
  useEffect(() => {
    setItemPerPage(30);
  }, [type]);

  return (
    <section id={`partners-list-${type}`}>
      <RouletteSpinnerOverlay
        loading={isUninitialized || isLoading}
        color={theme.palette.primary.main}
      />
      {data &&
        type !== PARTNER_TYPE.FAVORITE_PARTNERS &&
        data['hydra:member']?.length === 0 && (
          <NoSearchFound
            isFavorite={isFavorite}
            text1={
              isFavorite
                ? intl.formatMessage({id: 'partners.no_favorites.title'})
                : intl.formatMessage({id: 'common.no_results_found'})
            }
            text2={
              isFavorite
                ? intl.formatMessage({id: 'partners.no_favorites.subtitle'})
                : null
            }
          />
        )}

      <Box sx={{pt: {xs: 1.6, md: 3}, px: {xs: 1.6, md: 2.4}}}>
        <Grid container columnSpacing={2} rowSpacing={{xs: 2.8, md: 2.7}}>
          {data &&
            (type === PARTNER_TYPE.FAVORITE_PARTNERS
              ? Array.isArray(data) &&
                data?.map((partner, index) => (
                  <Grid item key={index} xs={6} md={4}>
                    <Item {...partner.merchant} tab={type} />
                  </Grid>
                ))
              : Array.isArray(data['hydra:member']) &&
                data['hydra:member']?.map((partner: any, index: number) => (
                  <Grid item key={index} xs={6} md={4}>
                    <Item {...partner} tab={type} />
                  </Grid>
                )))}
        </Grid>
      </Box>
    </section>
  );
};

export default List;
