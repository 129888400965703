import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from './functions';
import {PARTNER_TYPE} from '../constants';

export type ListPartnersParams = {
  search: string;
  type: PARTNER_TYPE;
  itemsPerPage: number;
};
export type FavoritePartnerParams = {
  id: string;
};

export const listURL = (type: string) => {
  switch (type) {
    case PARTNER_TYPE.FAVORITE_PARTNERS: {
      return '/my-favorites';
    }
    default: {
      return '/merchants';
    }
  }
};

export const queryParams = (type: string) => {
  const params: any = {};
  if (PARTNER_TYPE.FAVORITE_PARTNERS !== type) {
    if (PARTNER_TYPE.ALL_PARTNERS !== type) {
      params.online = PARTNER_TYPE.ONLINE_PARTNERS === type;
    }
    params.page = 1;
  }

  return params;
};

export const PartnerApi = createApi({
  reducerPath: 'PartnerApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['list'],
  endpoints: builder => ({
    list: builder.query<Array<any>, ListPartnersParams>({
      query: ({type, search = '', itemsPerPage}: ListPartnersParams) => ({
        url: listURL(type),
        method: 'GET',
        params: {
          ...queryParams(type),
          simplesearch: search,
          itemsPerPage,
        },
      }),
      // Refetch when the query args changes
      forceRefetch({currentArg, previousArg}) {
        return currentArg !== previousArg;
      },
      // used to unvalidate cache when favorite changes
      providesTags: ['list'],
    }),
    detail: builder.query({
      query: (id: string) => ({
        url: '/merchants/' + id,
        method: 'GET',
      }),
    }),
    favorite: builder.mutation<any, FavoritePartnerParams>({
      query: ({id}: FavoritePartnerParams) => ({
        url: `/my-favorites/favorite/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['list'],
    }),
    unfavorite: builder.mutation<any, FavoritePartnerParams>({
      query: ({id}: FavoritePartnerParams) => ({
        url: `/my-favorites/unfavorite/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['list'],
    }),
  }),
});

export const {
  useListQuery,
  useDetailQuery,
  useFavoriteMutation,
  useUnfavoriteMutation,
} = PartnerApi;
