import {BaseQueryFn} from '@reduxjs/toolkit/query/react';
import axios, {AxiosError, AxiosRequestConfig} from 'axios';

import {RootState} from '../store/store';

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
      sendAuth?: boolean;
    },
    unknown,
    unknown
  > =>
  async (
    {url, method, data, params, headers = {}, sendAuth = true},
    {getState}
  ) => {
    try {
      let token = (getState() as RootState).auth.token;
      if (!token) {
        token = localStorage.getItem('token')?.toString();
      }
      if (token && sendAuth && !('Authorization' in headers)) {
        headers = {
          ...headers,
          Authorization: `Bearer ${token}`,
        };
      }
      const result = await axios({
        url: process.env.REACT_APP_API_URL + url,
        method,
        data,
        params,
        headers,
      });
      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
